<template>
  <div>
    <formEditor />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    formEditor() {
      return import("@/components/business/form/preview/");
    }
  },
  props: {},
  data() {
    return {
      default: [
        {
          id: 1580201858868,
          type: "header",
          subtype: "h2",
          label: this.$t("data.form[0].title"),
          columnName: "12",
          description: "",
          required: false
        },
        {
          id: 1580201853354,
          type: "paragraph",
          subtype: "p",
          description: this.$t("data.form[1].title"),
          columnName: "12",
          required: false,
          value: ""
        },
        {
          id: 1580201857939,
          type: "header",
          subtype: "h3",
          label: this.$t("data.form[2].title"),
          columnName: "12",
          description: "",
          required: false
        },
        {
          id: 1580201857398,
          type: "text",
          subtype: "text",
          label: this.$t("data.form[3].title"),
          columnName: "12",
          description: "",
          required: true
        },
        {
          id: 1580201859290,
          type: "text",
          subtype: "text",
          label: this.$t("data.form[4].title"),
          columnName: "12",
          description: "",
          required: true
        },
        {
          id: 1580201857321,
          type: "text",
          subtype: "email",
          label: this.$t("data.form[5].title"),
          columnName: "12",
          description: this.$t("data.form[5].info"),
          required: true
        },
        {
          id: 1580201853552,
          type: "text",
          subtype: "tel",
          label: this.$t("data.form[6].title"),
          columnName: "12",
          description: this.$t("data.form[6].info"),
          required: false
        },
        {
          id: 1580201854637,
          type: "textarea",
          label: this.$t("data.form[7].title"),
          columnName: "12",
          description: this.$t("data.form[7].info"),
          required: false
        },
        {
          id: 1580201862754,
          type: "header",
          subtype: "h3",
          label: this.$t("data.form[8].title"),
          columnName: "12",
          description: "",
          required: false
        },
        {
          id: 1580201859939,
          type: "terms",
          label: this.$t("data.form[9].title"),
          columnName: "12",
          required: true,
          description: this.$t("data.form[9].info"),
          values: [
            {
              label: this.$t("data.form[9].options[0].title"),
              value: this.$t("data.form[9].options[0].info")
            },
            {
              label: this.$t("data.form[9].options[1].title"),
              value: this.$t("data.form[9].options[1].info")
            },
            {
              label: this.$t("data.form[9].options[2].title"),
              value: this.$t("data.form[9].options[2].info")
            }
          ]
        }
      ],
      form: this.$store.state.business.unsaved.form
    };
  },
  computed: {},
  mounted() {
    if (this.$store.state.business.unsaved.form.length === 0) {
      this.form = this.default;
      this.setSave();
    }
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form = this.form;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
